<template>
  <div>
    <div class="partners__summary">
      <p-big-number
        data-test="registered-partners"
        :number="sellers || '-'"
        title="Distributeurs partenaires d'aladin"
        iconName="category-cooperative"
        class="buyers__big-number--bg-primary partners__summary__card"
      ></p-big-number>
      <p-big-number
        data-test="registered-farmers"
        :number="registeredFarmers"
        title="Agriculteurs inscrits sur aladin"
        iconName="category-adherents"
        class="buyers__big-number--bg-green partners__summary__card"
      ></p-big-number>
      <p-big-number
        data-test="registered-technical-sales"
        :number="registeredTechnicalSales"
        title="Techniciens inscrits sur aladin"
        iconName="category-salesman"
        class="buyers__big-number--bg-green partners__summary__card"
      ></p-big-number>
      <p-big-number
        data-test="referenced-categories"
        :number="referencedCategories"
        title="Gammes disponibles sur aladin"
        iconName="category-tractor"
        class="buyers__big-number--bg-purple partners__summary__card"
      ></p-big-number>
      <p-big-number
        data-test="referenced-products"
        :number="referencedProducts"
        title="Produits disponibles sur aladin"
        iconName="function-shopping-cart"
        class="buyers__big-number--bg-purple partners__summary__card"
      ></p-big-number>
    </div>
    <p-card>
      <h2 class="partners__title">Les distributeurs partenaires d'aladin</h2>
      <h4 class="partners__subtitle bold">Les produits à la vente concernent les produits ayant une offre en cours à l’instant T</h4>

      <p-load-spinner :isDisplayed="!isLoaded"></p-load-spinner>

      <div class="partners__cards" v-if="isLoaded">
        <p-buyers-partner-card
          v-for="partner in orderedPartners"
          :key="partner.partner_id"
          :productsCount="getProductCount(partner)"
          :partner="partner"
          class="partners__card"
        ></p-buyers-partner-card>
      </div>
    </p-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PCard from '@/presenters/PCard/PCard.vue'
import PBuyersPartnerCard from '@/presenters/PBuyersPartnerCard/PBuyersPartnerCard.vue'
import PLoadSpinner from '@/presenters/PLoadSpinner/PLoadSpinner.vue'
import PBigNumber from '@/presenters/PBigNumber/PBigNumber.vue'

export default {
  name: 'CBuyersPartners',
  components: {
    PCard,
    PBuyersPartnerCard,
    PLoadSpinner,
    PBigNumber,
  },
  data() {
    return {
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters(['partners', 'partnerSummary', 'globalSummary']),
    orderedPartners() {
      if (this.partnerSummary)
        return [...this.partners]
          .filter((partner) => this.isNotDisplayedPartner(partner.id))
          .sort((partner1, partner2) => this.getProductCount(partner2) - this.getProductCount(partner1))
      return this.partners
    },
    supplierSellers() {
      return this.partnerSummary.sellers || []
    },
    sellers() {
      return this.globalSummary.sellers
    },
    registeredFarmers() {
      return this.globalSummary.registered_farmers
    },
    registeredTechnicalSales() {
      return this.globalSummary.registered_technical_sales
    },
    referencedProducts() {
      return this.globalSummary.referenced_products
    },
    referencedCategories() {
      return this.globalSummary.referenced_categories
    },
  },
  methods: {
    getProductCount(partner) {
      const partnerSales = this.supplierSellers.find((seller) => seller.partner_id === partner.id)
      return partnerSales ? partnerSales.referenced_products : 0
    },
    isNotDisplayedPartner(partnerId) {
      return ![9, 12, 13, 23, 25, 26, 17, 27, 7, 14, 19, 20].includes(partnerId)
    },
  },
  async beforeMount() {
    await this.$store.dispatch('fetchPartners')
    await this.$store.dispatch('fetchPartnerSummary')

    this.isLoaded = true
  },
}
</script>

<style lang="scss">
.partners {
  &__title {
    margin-bottom: var(--idf-spacing-1);
  }

  &__subtitle {
    margin-bottom: var(--idf-spacing-4);
  }

  &__summary {
    display: flex;
    flex-wrap: nowrap;
    gap: $spacing-3;

    &__card {
      flex-grow: 1;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
</style>
